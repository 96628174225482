import * as React from 'react';
import { CloseButton } from '../CloseButton';
import { SidebarContainer } from './SidebarContainer.part';
import { PositionType } from './SidebarPopup.types.part';
import { SidebarBlocker } from './SidebarBlocker.part';
export interface SidebarPopupProps {
    position?: PositionType;
    size?: string;
    open?: boolean;
    onClose?: () => void;
}
export const SidebarPopup = Object.assign(((({ open = false, size, position, children, onClose, ...props }) => (<>
    <SidebarContainer {...props} open={open} position={position} size={size}>
      <CloseButton onClick={onClose}/>
      {children}
    </SidebarContainer>
    <SidebarBlocker open={open} onClose={onClose}/>
  </>)) as React.FC<SidebarPopupProps>), { inner: {
        get SidebarContainer() { return SidebarContainer as typeof SidebarContainer; },
        get CloseButton() { return CloseButton as typeof CloseButton; },
        get SidebarBlocker() { return SidebarBlocker as typeof SidebarBlocker; }
    } });
